import { template as template_dc0c25d30ce6433aa5aa8f51114012bb } from "@ember/template-compiler";
const WelcomeHeader = template_dc0c25d30ce6433aa5aa8f51114012bb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
