import { template as template_47a123a5f39a4d7b84177b733b3d8a19 } from "@ember/template-compiler";
import AnonymousSections from "./anonymous/sections";
import UserSections from "./user/sections";
const SidebarSections = template_47a123a5f39a4d7b84177b733b3d8a19(`
  {{#if @currentUser}}
    <UserSections
      @collapsableSections={{@collapsableSections}}
      @panel={{@panel}}
      @hideApiSections={{@hideApiSections}}
    />
  {{else}}
    <AnonymousSections @collapsableSections={{@collapsableSections}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSections;
