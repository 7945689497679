import { template as template_a3eeb349e0684ab6ad0e3e016bc4dd3e } from "@ember/template-compiler";
const FKLabel = template_a3eeb349e0684ab6ad0e3e016bc4dd3e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
