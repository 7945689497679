import { template as template_1e4caf541d4149428251b49230266e0e } from "@ember/template-compiler";
const FKText = template_1e4caf541d4149428251b49230266e0e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
